.contact {
  text-align: center;
  padding: 20px;
  background-color: #FFFCF6;
  color: #333;
}

.contact h1 {
  font-size: 2em;
  margin-bottom: 20px;
}

.contact-info p {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.contact-info a {
  color: #FF8C00;
  text-decoration: none;
}

.contact-info a:hover {
  text-decoration: underline;
}

.opening-hours {
  margin: 20px 0;
}

.opening-hours h2 {
  font-size: 1.8em;
  margin-bottom: 10px;
}

.opening-hours ul {
  list-style-type: none;
  padding: 0;
}

.opening-hours ul li {
  font-size: 1.2em;
  margin-bottom: 5px;
}

.map {
  margin: 20px 0;
}

.map h2 {
  font-size: 1.8em;
  margin-bottom: 10px;
}
.map iframe {
  width: 100%;
  height: 450px;
  max-width: 100%;
}

