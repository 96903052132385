.projects {
  text-align: center;
  padding: 20px;
  background-color: #FFFCF6;
  color: #333;
}

.projects h1 {
  font-size: 2em;
  margin-bottom: 20px;
}

.project {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 20px;
  text-align: left;
  background: #f5e8cc;
  border: 1px solid #e5d4b8;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  
}

.project h2 {
  font-size: 1.8em;
  margin-bottom: 10px;
  text-align: center;
}

.project h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.project ul {
  list-style-type: disc;
  padding-left: 20px;
}

.project p, .project ul {
  font-size: 1.2em;
}
