.footer {
  background-color: #F9EDD6; /* Сделайте фон футера совпадающим с общим фоном */
  color: black; /* Установите цвет текста */
  text-align: center;
  padding: 10px 0;
  width: 100%; /* Убедитесь, что ширина 100% */
  box-sizing: border-box; /* Включите отступы и границы в ширину */
  border-top: 1px solid black; /* Добавьте черную рамку сверху */
  margin-top: auto; /* Это позволит футеру оставаться внизу */
}
