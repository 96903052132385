.prislista {
  text-align: center;
  padding: 20px;
  background-color: #FFFCF6; /* Светлый фон */
  color: #333;
}

.prislista h1 {
  font-size: 2em;
  margin-bottom: 20px;
}

.pricing {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Четыре колонки для больших экранов */
  gap: 20px;
  justify-items: stretch;
  align-items: stretch;
}

.category-title {
  grid-column: span 4; /* Заголовок занимает все 4 колонки на десктопе */
  font-size: 1.8em;
  margin-bottom: 10px;
  text-align: center;
}

.price-item {
  background: linear-gradient(to bottom, #fffaf0, #f5e8cc); /* Пастельный градиент */
  border: 1px solid #e5d4b8;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border: 1px solid #ccc;
  text-align: left;
  flex: 1 1 auto;
  min-width: 0;
  word-wrap: break-word;
  word-break: break-word;
}

.price-item h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.price-item p, 
.price-item input {
  font-size: 1.2em;
  margin: 0;
  width: auto;
  text-align: left;
  box-sizing: border-box;
}

/* Адаптация для мобильных устройств */
@media (max-width: 768px) {
  .pricing {
    grid-template-columns: 1fr; /* Одна колонка на маленьких экранах */
    gap: 10px;
  }

  .category-title {
    grid-column: span 1; /* Заголовок занимает только одну колонку на мобильных устройствах */
  }

  .price-item {
    max-width: 100%;
    padding: 15px; /* Уменьшение отступов */
  }

  .prislista h1 {
    font-size: 1.5em; /* Уменьшение размера заголовка на små экраны */
  }

  .price-item h3 {
    font-size: 1.3em; /* Уменьшение размера текста заголовка на små экраны */
  }

  .price-item p, 
  .price-item input {
    font-size: 1em; /* Уменьшение размера текста */
  }
}
