/* Стили для всех текстовых областей */
textarea {
  width: 100%;
  height: 120%;
  padding: 10px;
  font-size: 1.2em;
  box-sizing: border-box;
  resize: vertical;

}

/* Стили для текстовых областей с классом .editable-textarea */
.editable-textarea {
  width: 100%;
  height: 120%;
  padding: 10px;
  font-size: 1.2em;
  box-sizing: border-box;
  resize: vertical;
}

/* Остальные стили страницы */
.home {
  text-align: center;
  padding: 20px;
  background-color: #FEFFFF;
  color: #333;
}

.home h1 {
  font-size: 2em;
  margin-bottom: 20px;
}

.home p {
  font-size: 1.4em;
  margin-bottom: 20px;
  text-align: left;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  max-width: 100%;
  overflow: hidden;
}

.services {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.card {
  background: linear-gradient(to bottom, #fffaf0, #f5e8cc);
  border: 1px solid #e5d4b8;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
}


.card h2 {
  font-size: 1.8em;
  margin-bottom: 10px;
}

.card p {
  font-size: 1.2em;
}

.home-image {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
  border-radius: 8px;
  margin: 0 auto;

  -webkit-mask-image: linear-gradient(to right, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0) 100%);
  mask-image: linear-gradient(to right, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0) 100%);

  
}

.button {
  display: inline-block;
  background-color: #f5a25d;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #e18f4a;
}

.faq {
  text-align: left;
}

.faq h2 {
  font-size: 2em;
  margin-bottom: 20px;
}

.faq-item {
  padding: 15px;
  background: linear-gradient(to bottom, #fffaf0, #f5e8cc);
  border: 1px solid #e5d4b8;
  border-radius: 8px;
  margin-bottom: 10px;
  cursor: pointer;
  max-width: 60%;
  word-wrap: break-word;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.faq-item h3 {
  font-size: 1.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq-item p {
  font-size: 1.2em;
}

.faq-item .arrow {
  font-size: 0.8em;
}

@media (max-width: 768px) {
  .content {
    flex-direction: column;
  }

  .home-image {
    width: 100%;
    height: auto;
    margin-top: 20px;
  }

  .services {
    max-width: 100%;
  }

  .card {
    max-width: 100%;
  }

  .faq-item {
    max-width: 100%;
  }
}

@media (min-width: 769px) {
  .home-image {
    max-width: 50%;
    width: auto;
  }
}
