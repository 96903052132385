/* Основные стили для HTML и body */
html, body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow-x: hidden; /* Отключите горизонтальную прокрутку */
  background-color: #D7CFBF; /* Светло-серый фон */
  font-family: 'Arial', sans-serif; /* Основной шрифт */
}

/* Стили для основного контейнера приложения */
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Стили для основного контента */
main {
  flex: 1;
  padding: 20px;
}

/* Стили для заголовков */
h1, h2, h3, h4, h5, h6 {
  color: #333; /* Темный цвет текста */
}

/* Стили для параграфов */
p {
  color: #666; /* Серый цвет текста */
  line-height: 1.6; /* Межстрочный интервал */
}

/* Стили для ссылок */
a {
  color: #FF8C00; /* Оранжевый цвет ссылок */
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Стили для кнопок */
button, .button {
  display: inline-block;
  background-color: #FF8C00;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  border: none;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

button:hover, .button:hover {
  background-color: #e67600;
}

/* Стили для карточек */
.card {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}
