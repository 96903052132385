@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Open+Sans:wght@400;700&family=Playfair+Display:wght@700&display=swap'); /* Importera Google Fonts */

.header {
  background-color: #F9EDD6; /* Fallback-bakgrundsfärg */
  background-image: url('../../image/vax9.webp'); /* Bakgrundsbild */
  background-size: cover; /* Bilden täcker hela headern */
  background-position: center; /* Bilden är centrerad */
  color: black; /* Textfärg */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  border: 1px solid black; /* Svart ram */
  width: 100%;
  box-sizing: border-box;
  position: relative;
  z-index: 1; /* Headern ligger ovanför övrigt innehåll */
}

.header-logo {
  width: 350px;
  height: auto;
  max-height: 80px;
  object-fit: contain;
  
  position: relative; /* Positionering för att kunna använda ::before */
}



.logo:hover, .logo:focus {
  text-decoration: none; /* Ta bort understrykning vid hover och focus */
}

.logo::before {
  content: ''; /* Nödvändigt för att pseudo-element ska visas */
  position: absolute;
  top: -10px;
  left: -20px;
  right: -20px;
  bottom: -10px;
  border-radius: 10px; /* Rundade hörn */
  z-index: -1; /* Placera bakgrundsfärgen bakom texten */
}

.nav-links ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap; /* Tillåter radbrytning */
  font-size: larger;
}

.nav-links ul li {
  margin: 0 10px;
}

.nav-links ul li a {
  color: black; /* Textfärg */
  text-decoration: none;
  padding: 5px 10px; /* Padding för länkar */
  border-radius: 5px; /* Rundade hörn */
  transition: background-color 0.5s; /* Prydlig övergång */
  background-color: rgba(247, 234, 238, 0.8); /* Halvgenomskinlig bakgrundsfärg */
  border: 1px solid rgba(0, 0, 0, 0.1); /* Halvgenomskinlig kantlinje */
  font-family: 'Open Sans', sans-serif; /* Använd Google Fonts */
  font-size: 1em;
}

.nav-links ul li a:hover,
.nav-links ul li a.active {
  background-color: rgba(243, 206, 161, 0.8); /* Bakgrundsfärg vid hover och active */
}

.hamburger {
  display: none; /* Döljer hamburger-menyn som standard */
  flex-direction: column;
  cursor: pointer;
  z-index: 2; /* Se till att hamburger-menyn ligger ovanför logotypen */
}

.hamburger .line {
  width: 25px;
  height: 3px;
  background-color: black;
  margin: 4px 0;
}

/* Responsiv design för mobila enheter */
@media (max-width: 768px) {
  .nav-links {
    display: none; /* Döljer navigationen som standard */
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff6f6; /* Samma bakgrundsfärg som headern */
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    z-index: 0; /* Navigationen ligger under headern */
  }

  .nav-links.open {
    display: flex; /* Visar navigationen när den är öppen */
  }

  .nav-links ul {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .nav-links ul li {
    width: 100%;
    text-align: center;
    margin: 10px 0;
  }

  .nav-links ul li a:hover,
  .nav-links ul li a.active {
    background-color: rgba(243, 206, 161, 0.8); /* Bakgrundsfärg vid hover och active */
  }

  .hamburger {
    display: flex; /* Visar hamburger-menyn */
    position: absolute;
    right: 30px; /* Placera hamburger-menyn till höger */
  }
  .header-logo {
    width: 270px;
 }
}
