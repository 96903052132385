.about {
    padding: 20px;
    text-align: center;
    padding: 50px 20px;
    text-align: center;
    background-color: #FFFCF6;
  }
  
  .about h2 {
    margin-bottom: 20px;
  }

  
.container {
  max-width: 800px;
  margin: 0 auto;
}

.about-heading {
  font-size: 2.5em;
  color: #333;
  margin-bottom: 20px;
  font-weight: bold;
}

.about-text {
  font-size: 1.2em;
  color: #555;
  line-height: 1.6;
  margin-bottom: 20px;
}
  